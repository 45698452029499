import { Component } from '@angular/core';
import { SwStateMachineService } from '../../services/sw-state-machine.service';

@Component({
  selector: 'ava-smart-weather-loading',
  templateUrl: './loading.component.html',
})
export class LoadingComponent {
  constructor(public swSms: SwStateMachineService) {}
}
